<script setup lang="ts">
import type { VideoJsPlayer as IPixellotPlayer } from "@pixellot/web-sdk";
import type { IPlayerState, IPixellotPlayerSource } from "~~/modules/pixellot-sdk";

defineProps<{
  pipActive: boolean;
  player: IPixellotPlayer;
  source: IPixellotPlayerSource;
  state: IPlayerState;
}>();

const emit = defineEmits(["close", "exit-pip"]);
const close = () => emit("close");
const exitPip = () => emit("exit-pip");
</script>

<template>
  <transition
    v-if="state.hasStarted && !state.error"
    enter-from-class="opacity-0"
    leave-to-class="opacity-0"
    enter-active-class="transition duration-300"
    leave-active-class="transition duration-300"
  >
    <div
      v-show="pipActive && state.userActive"
      class="absolute left-0 top-0 z-0 grid size-full place-items-center bg-black/30 transition-all duration-500"
      @click.self="state.paused ? player.play() : player.pause()"
    >
      <UButton
        class="absolute left-2 top-2 rotate-[270deg] !p-0"
        variant="clean"
        :icon="{ name: 'external-link', class: 'h-5 w-5' }"
        @click="exitPip()"
      />
      <UButton
        class="absolute right-2 top-2 !p-0"
        variant="clean"
        :icon="{ name: 'close', class: 'h-5 w-5' }"
        @click="close()"
      />

      <UButton
        class="!p-0"
        variant="clean"
        :icon="{ name: state.paused ? 'play' : 'pause', class: 'h-10 w-10' }"
        @click="state.paused ? player.play() : player.pause()"
      />
    </div>
  </transition>
</template>
